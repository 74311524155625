.facility-section {
    background: #DED7DE;
}

.future-p3 {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 150%;
    /* or 33px */


    /* Black */

    color: #0A0A13;
}

.future-h5 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;

    /* Dark Blue */

    color: #191B51;
}

.facility-first-sec {
    margin-top: -8rem;
}

.facility-first-sec img {
    width: 100%;
    mix-blend-mode: multiply;
}

.ese-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ese-image img {
    width: 100%;
    cursor: pointer;
}

.intervention {
    background: #DED7DE;
}

.sec-left-new {
    font-family: 'Italiana';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 76%;
    /* or 49px */

    text-align: center;

    /* Black */
    margin-top: 7rem;
    margin-bottom: 2rem;
    color: #0A0A13;
}

.class-inter {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */


    /* Black */

    color: #0A0A13;
}

.align-items-new {
    padding: 0px 50px;
}

.key-img1 {
    position: relative;
}

.key-img {
    display: flex;
    position: relative;
    z-index: 1;
}

.key-img img {
    width: 100%;
}

.key-img2 {
    position: absolute;
    top: -12px;
    left: -200px;
    /* background: #D0F3FF; */
    mix-blend-mode: soft-light;
    border-radius: 20px;
    transform: rotate(3deg);
    z-index: 1;
}

.header-pad-fac {
    display: flex;
    align-items: center;
    padding: 30px 85px;

}

.top-class-fac {
    padding-bottom: 5rem;
}

.back-rotate-image {
    animation: backRotateAnimation 1s ease-in-out;
    /* Adjust the duration (1s in this example) */
}

@keyframes backRotateAnimation {
    from {
        transform: rotate(60deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@media screen and (max-width:1024px) {
    .outerRow1 {
        width: 95.8rem !important;
    }

    .ese-image img {
        width: 363px !important;
    }
}

@media screen and (max-width:768px) {
    .outerRow1 {
        width: 65rem !important;
    }

    .ese-image img {
        width: 275px !important;
    }
}

@media screen and (max-width:991px) {
    .key-img2 {
        top: -271px;
    }
}

@media screen and (max-width: 575px) {
    .header-pad-fac {
        padding: 70px 48px !important;
    }

    .sec-left-new {
        font-size: 38px !important;
    }

    .class-inter {
        font-size: 15px;
    }

    .align-items-new {
        padding: 0px 30px;
    }

    .future-h5 {
        font-size: 35px;
    }

    .future-p3 {
        font-size: 15px;
    }
}

@media screen and (max-width: 1165px) {
    .header-pad-fac {
        padding: 70px 48px !important;
    }
}

.content {
    transition: all 0.8s linear;
    position: relative;
    /* z-index: 1; */
}

.content2 {
    transition: all 0.8s linear;
}

#open2 {
    transform: translateX(0px);
    display: none;
}

/* .open label{
    background: #999;
    height: 16px;
    width: 16px;
    display:inline-block;
    padding: 0 0 0 0px;
} */
#open2:checked~label .content {
    transform: translateX(101rem) !important;
}

#open2:checked~label .content2 {
    transform: translateX(50.5rem) !important;
}

/* .section-env{
    display: none;
} */
.btn-check-after {
    width: 100%;
    padding: 13px 15px;
    position: relative;
    background: #0A0A13;
    mix-blend-mode: screen;
    border-radius: 5px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* align-items: center; */
    text-align: inherit;
    color: #FFFFFF;
    margin-bottom: 3rem;
    border: none;
}

.btn-new-after {
    /* display: flex;
    justify-content: space-between; */
    position: absolute;
    left: 60px;
    top: 30%;
    width: 55%;
}

.btn-check-after:focus {
    width: 100%;
    padding: 13px 15px;
    position: relative;
    background: #ffffff;
    mix-blend-mode: screen;
    border-radius: 5px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* align-items: center; */
    text-align: inherit;
    color: #000000;
    margin-bottom: 3rem;
    border: none;
}

.btn-check-after:focus .blackcolor {
    fill: black !important;
}

.images-sec {
    background: #052B0E;
}

.svg-arrow2 {
    float: right;
    display: flex;
    position: relative;
    top: 0px;
}

.ul-right {
    padding: 0px 14px;
}

.li-right {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 2rem;
    list-style-type: disc;
}

label {
    display: contents;
    max-width: 100%;
    /* margin-bottom: 5px; */
    font-weight: 700;
    /* width: 33%; */
}

/* .open + label{
    background: #999;
    height: 16px;
    width: 16px;
    display:inline-block;
    padding: 0 0 0 0px;
} */
.new-class2 {
    margin-top: 3rem;
}

.ese2 {
    display: contents;
}

/* .btn-check-after{
       margin: 0 5px;
} */

@media (min-width:320px) and (max-width:425px) {
    .btn-new-after {
        top: 21%;
    }

    .ese-image {
        height: 450px;
    }

    .ese-image img {
        height: 450px;
    }

    .svg-arrow2 {
        width: 20px;
    }

    .li-right {
        font-size: 12px;
    }

    .btn-check-after {
        font-size: 11px;
    }

    .facility-main-img {
        margin-left: 25px;
        max-width: 95%;
    }
}

@media (min-width:426px) and (max-width:522px) {
    .btn-new-after {
        top: 20%;
    }

    .li-right {
        font-size: 14px;
    }

    .btn-check-after {
        font-size: 15px;
    }

    .facility-main-img {
        margin-left: 25px;
        max-width: 95%;
    }
}

@media (min-width:1600px) {
    #open2:checked~label .content {
        transform: translateX(90vw);
    }

    #open2:checked~label .content2 {
        transform: translateX(40vw);
    }

    #open2:checked~label .content2 {
        transform: translateX(85vw);
    }

    .li-right {
        font-size: 1.5vw;
        line-height: 2vw;
    }

    .btn-check-after {
        font-size: 1.3vw;
        line-height: 2vw;
    }

}

/* @media (min-width:1801px) and (max-width:2000px){
    .ul-right{
        margin-right: 23rem;
        margin-left: -8rem;
    }

}

@media (min-width:2001px) and (max-width:2200px){
    .ul-right{
        margin-right: 33rem;
        margin-left: -10rem;
    }
.li-right{
    font-size: 19px;
    line-height: 31px;
}
}
@media (min-width:2201px) and (max-width:2400px){
    .ul-right{
        margin-right: 46rem;
        margin-left: -12rem;
    }
.li-right{
    font-size: 22px;
    line-height: 35px;
}
} */

@media (min-width:320px) and (max-width:472px) {
    #open2:checked~label .content {
        transform: translateX(180vw);
    }

    #open2:checked~label .content2 {
        transform: translateX(100vw);
    }
}

.fac-btndiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.ese2 {
    white-space: nowrap;
    overflow: scroll;
}