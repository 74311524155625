.accordion-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  background-color: #f8f8f8;
}

.accordion-content {
  padding: 10px;
  background-color: #fff;
}
css
Copy code
/* Accordion.css */

.accordion-container {
  max-width: 600px;
  margin: 20px auto;
}

