.nav11{
    display: flex;
    justify-content: end;
}
.dropdown{
    margin-bottom: 0 !important;
}
.nh-text-font{
    display: flex;
    align-items: center;
}
.nh-flex{
    align-items: center;
    display: flex;
    justify-content: end;
    padding: 0px 22px;
}
.ul-alll{
    display: flex;
    justify-content: end;

}
.ul-alll li{
    /* padding: 0; */
    display: flex;
    align-items: center;
}
.nh-eng-drop-res li{
    padding: 0%;
    width: 100%;
}
.dropdown1{
    /* margin-top: 2rem; */
}
.nh-btn-new{
    /* margin-top: 1rem; */
}
.nh-img{
    margin-left: 4rem;
}
.btn.focus, .btn:focus, .btn:hover{
    /* background: none !important;
    color: black !important; */
    border: black !important;

}
.nh-btn-new:hover{
    background: white !important;
    color: #0A0A13 !important;
}
.eng-pad{
    padding-top: 2rem !important;
}
.navbar-expand-lg .navbar-nav .nav-link{
    color: #0A0A13 !important;
}
.navbar-nav>li>a{
    padding-bottom: 0px;
}
.dropdown-menu[data-bs-popper]{
    left: -120px !important;
    top: 65px;
}
.headerSearchButton{
    border-radius: 0px 10px 10px 0px !important;
    /* border: none; */
    width: 25%;
    background: #191B51 !important;
    color: #ffffff;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
@media (max-width:375px) and (min-width:320px){
    .res-menu-middle{
        /* margin-left: 0rem !important; */
        text-align: center;
        display: block !important;
    }
    
}
@media (max-width:425px) and (min-width:375px){
    .res-menu-middle{
        margin-left: 0rem !important;
        text-align: center;

    }
}
@media (max-width:768px) and (min-width:425px){
    /* .res-menu-middle{
        margin-left: 0rem !important;
        text-align: center;

    } */
}
@media (max-width:992px) {
    /* .res-menu-middle{
        margin-left: 0rem !important;
        text-align: center;

    } */
    .collapse:not(.show){
        display: none !important;
    }
}
@media (max-width:375px) and (min-width:320px){
    .res-menu-middle{
        /* margin-left: 10rem; */
    }
}
@media (max-width:1278px) and (min-width:992px){
    .ul-alll  {
display: flex;
/* justify-content: space-around; */
gap: 4rem;
align-items: center;
    }
    
}
@media (max-width:2200px) and (min-width:1280px){
    .ul-alll  {
display: flex;
/* justify-content: space-around; */
gap: 4rem;
align-items: center;
    }
    
}
@media (max-width:992px) and (min-width:320px){
    .nh-drop-left{
        margin-left: -9rem !important;
    }
    .nh-eng-drop-res{
        /* padding: 20px !important; */
        /* padding: 5px 35px !important; */
    }
    nav li a{
     display: block !important;   
    }
    .navbar-nav{
        margin: 0 !important;
    }
    .nh-flex{
        display: flex;
        justify-content: center;
    }
    .nh-text-font{
        display: block;
    }
    .ul-alll li{
        display: block;
        align-self: center;
    }
    .pro-drop-menu{
margin-top: 60px !important;
    }
    .dropdown1{
        display: flex !important;
        justify-content: center !important;
    }
    .dropdown-btn-lang{
        float: none !important;
    }
    .dropdown-btn-lang ul{
        max-inline-size:"min-content" !important;
        inset-inline-start:"auto" !important;
        width: min-content !important;
    }    
}
.pro-icon{
    margin: 0;
}
/* @media (min-width: 768px){
.navbar-collapse.collapse {
    display: none !important;
    height: auto!important;
    padding-bottom: 0;
    overflow: visible!important;
}
} */
.eng-pad::after{
    content: none !important;
}
.nh-notifi::after{
    content: none !important;
}
.ghanta-arrow{
    margin-left: 1rem;
}
.nh-drop-left li{
    padding: 2px !important;
}