/* .warpper {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
/* input[type="radio"] {
    display: none;
  } */
.tabs {

  border-bottom: 1px solid black;
  margin-bottom: 5rem;

}

.warpper-dash {
  padding: 0 70px;
}

.tab {
  cursor: pointer;
  padding: 10px 20px;
  /* margin: 0px 2px; */
  background: transparent;
  display: inline-block;
  color: #fff;
  border-radius: 3px 3px 0px 0px;
  /* box-shadow: 0 0.5rem 0.8rem #00000080;*/
  margin-bottom: 0 !important;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
}

.panels {
  width: 100%;
  border-radius: 3px;
  overflow: hidden;

}

.panel {
  animation: fadein 0.8s;
}

.panels .panel {
  display: none;
  padding: 20px;
  background: none !important;
  border-radius: 0 5px 5px 5px;
}

.tabs label:not(:first-of-type) {
  border-left: none;
}

.panels .panel:not(:first-of-type) {
  border-top: none;
}

.not-found-div{
  display: flex;
  justify-content: center;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.panel-title {
  font-size: 1.5em;
  font-weight: bold;
}

.radio {
  display: none;
}

#one:checked~.panels #one-panel,
#two:checked~.panels #two-panel,
#three:checked~.panels #three-panel {
  display: block;
}

#one:checked~.tabs #one-tab,
#two:checked~.tabs #two-tab,
#three:checked~.tabs #three-tab {
  background: #191B51;
  ;
  color: #ffffff;
}

.card-dashboard {
  background: linear-gradient(259.26deg, #1FCF51 -3.47%, #349750 95.96%);
  border: 3px solid #FFFFFF;
  border-radius: 10px;
  padding: 25px 25px;
}

.card-dashboard h3 {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  /* identical to box height */

  letter-spacing: -0.02em;

  /* White */

  color: #FFFFFF;
}

.card-dashboard p {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.02em;

  /* White */

  color: #FFFFFF;
}

.card-orange {
  background: linear-gradient(259.48deg, #CF931F -3.46%, #D26F28 97.7%) !important;
  border: 3px solid #FFFFFF;
  border-radius: 10px;
}

.card-blue {
  background: linear-gradient(259.48deg, #1F46CF -3.46%, #2895D2 97.7%) !important;
  border: 3px solid #FFFFFF;
  border-radius: 10px;
}

.card-red {
  background: linear-gradient(259.48deg, #CF1F35 -3.46%, #D22847 97.7%) !important;
  border: 3px solid #FFFFFF;
  border-radius: 10px;
}


.wrapper-table {
  margin: 45px auto;
}

.table {
  margin: 0 0 40px 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
}

@media screen and (max-width: 580px) {
  .table {
    display: block;
  }
}

.row-check {
  display: table-row;
  background: #f6f6f6;
}

.row-check:nth-of-type(odd) {
  background: #e9e9e9;
}

.row-check.header {
  font-weight: 900;
  color: #ffffff;
  background: #ea6153;
}

.row-check.green {
  background: #27ae60;
}

.row-check.blue {
  background: #191B51;
}

@media screen and (max-width: 580px) {
  .row-check {
    padding: 14px 12px 7px;
    display: block;
  }

  .row-check.header {
    padding: 0;
    height: 6px;
  }

  .row-check.header .cell {
    display: none;
  }

  .row-check .cell {
    margin-bottom: 10px;
  }

  .row-check .cell:before {
    margin-bottom: 3px;
    content: attr(data-title);
    min-width: 98px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #969696;
    display: block;
  }

  .card-dashboard {
    margin-bottom: 1rem;
  }
}

@media (min-width:580px) and (max-width:992px) {
  .cell {
    padding: 12px 13px !important;
  }

  .card-dashboard {
    margin-bottom: 1rem;
  }
}

.cell {
  padding: 10px 70px;
  display: table-cell;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}

.cell a {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;

  /* Med Blue */

  color: #1C21AB !important;
}

.cell span {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
}

.cell .approve-btn {
  background: #1CAB4D;
  border-radius: 20px;
  border: navajowhite;
  padding: 4px 10px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  width: 100%;
}

.cell .pending-btn {
  background: #D37228;
  border-radius: 20px;
  border: navajowhite;
  padding: 4px 10px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  width: 100%;
}

.cell .rejected-btn {
  background: #c90000;
  border-radius: 20px;
  border: navajowhite;
  padding: 4px 10px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  width: 100%;
}

.cell .denied-btn {
  border-radius: 20px;
  border: navajowhite;
  padding: 4px 10px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  background: #D32846;
  width: 100%;
}

.cell .draft-btn {
  border-radius: 20px;
  border: navajowhite;
  padding: 4px 10px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  background: #2578D2;
  width: 100%;
}

@media screen and (max-width: 580px) {
  .cell {
    padding: 2px 16px;
    display: block;
  }
}




/* .menu-nav {
       
    display: flex;
    justify-content: space-between;
  } */

.menu-item {
  color: #FCC;
  padding: 3px;
}

.three-dots:after {
  cursor: pointer;
  color: black;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
  border: 1px solid black;
  border-radius: 4px;
}

.a-tag {
  text-decoration: none !important;
  color: white !important;
}

.a-tag .div-class {
  padding: 10px 10px;
}

.dropdown-action {
  position: absolute;
  right: 160px;
  background-color: #ffffff;
  outline: none;
  opacity: 0;
  z-index: -1;
  max-height: 0;
  border-radius: 10px;
}

/* transition: opacity 0.1s, z-index 0.1s, max-height: 5s; */

.dropdown-container:focus {
  outline: none;
}

.dropdown-container:focus .dropdown-action {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  /* transition: opacity 0.2s, z-index 0.2s, max-height: 0.2s; */
}