.logo-head{
    padding: 15px 70px;
}
.logo-head img{
    width: 100px;
}
.form-div{
    padding: 0 170px;
}
.right-side-image{
    background-image: url('/public/images/Group\ 24.jpeg');
    height: 100vh; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size:100% 100%;
    position: relative;
    border-radius: 8rem;
    left: 6rem;
}


.signup-head{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #0A0A13;
    margin-bottom: 1rem !important;
}

.form-group label{
    font-family: 'Work Sans';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 15px;
text-align: center;
letter-spacing: -0.02em;

/* Black */

color: #0A0A13;
}
.form-control:focus{
    background: #F0F3F3;
border-radius: 10px;
height: 50px;
margin-top: 0.5rem !important;
box-shadow: none;
border-color: transparent;
outline: none;
}
.form-control{
background: #F0F3F3;
border-radius: 10px;
height: 50px;
margin-top: 0.5rem !important;
}
.form-group::placeholder{
    font-family: 'Work Sans';
font-style: normal;
font-weight: 300;
font-size: 13px;
line-height: 15px;
text-align: center;
letter-spacing: -0.02em;

/* Black */

color: #0A0A13;

opacity: 0.8;
}

.form-group {
    margin-bottom: 15px;
}
.label-radio{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    text-align: center;
    letter-spacing: -0.02em;
    
    /* Black */
    
    color: #0A0A13;
}
.gender-label{
    margin-top: 0.5rem;
}

.account-btn{
    background: #191B51;
    border-radius: 10px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    width: 100%;
    border: none;
    padding: 15px 0px;
}
.login-bottom{
    margin-top: 2.5rem;
    display: flex;
    align-items: baseline;
}
.login-bottom a{
    margin-left: 20px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #1C21AB;
}
.login-bottom p{
    font-family: 'Work Sans';
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 15px;
letter-spacing: -0.02em;

color: #000000;
}
.class-sign{
    position: relative;
    overflow-x: hidden;
}

#emailOtp{
    display: none;
}
#mobileOtp{
    display: none;
}

@media screen and (max-width:768px){
    .form-div{
        padding: 0 50px;
    }
}

.pad-none{
    padding: 0;
}
