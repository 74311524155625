/* .image-slider {
  margin-left: 3rem;
  position: relative;
  display: inline-block;
  line-height: 0;
}

.image-slider img {
  user-select: none;
  max-width: 400px;
}


.image-slider > div {
  position: absolute;
  width: 25px;
  max-width: 100%;
  overflow: hidden;
  resize: horizontal;
}

.image-slider > div::before {
  content: '';
  display: block;
  width: 13px;
  height: 13px;
  overflow: hidden;
  position: absolute;
  resize: horizontal;
  right: 3px;
  bottom: 3px;
  background-clip: content-box;
  background: linear-gradient(-45deg, black 50%, transparent 0);
  -webkit-filter: drop-shadow(0 0 2px black);
  filter: drop-shadow(0 0 2px black);
} */
.img1{
  /* height: 100% !important; */
}
.slider-img img{
  width: 100%;
  height: 100%;
}
.slider__wrapper{
  height: 425px !important;
  width: 100% !important;

}
.owl-stage-outer{
  height: 430px !important;
}
.owl-carousel .owl-item img{
  height: 550px !important;
}