.about-section{
    background: linear-gradient(rgba(215, 190, 251, 0.5), rgba(227, 222, 253, 0.5)), url('/public/images/about background.png');
    height: 100%;
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
}
.future-p2{
    font-family: 'Barlow';
font-style: normal;
font-weight: 400;
font-size: 48px;
line-height: 150%;
color: #191B51;
}
.btn-lets2{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 20.1026px;
    line-height: 24px;
    margin-right: 2rem;
    /* White */
    
    color: #FFFFFF;
    background: #1C21AB;
border-radius: 12.5641px;
padding: 14px 12px;
border: none;
}
.about-first-sec p{
    font-family: 'Barlow';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 150%;
/* or 27px */

position: relative;
z-index: 1;
color: #000000;
}
.about-second-sec{
    background-image: url('/public/images/Background (1).png');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.slide-check p{
    font-family: 'Barlow';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 76%;
/* or 18px */
margin-bottom: 3rem !important;
text-align: center;
color: #FFFFFF;
}
.slide-check{
    display: flex;
    justify-content: center;
}
.slide-check svg{
    width: 11%;
    padding: 0px 11px;
}
/* .slide-check p::before,
.slide-check p::after{
    display: inline-block;
    content: "";
    border-top: 0.1rem solid white;
    width: 4rem;
    margin: 0 1rem;
    transform: translateY(-0.4rem);
} */
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background: #51BBDD !important;
}
.owl-theme .owl-dots .owl-dot span{
background: transparent !important;
border: 1px solid #51BBDD !important;
}
.image-frame{
    margin-bottom: 1.5rem !important;
}
.image-frame img{
    margin-bottom: 4rem;
}
.trans-name{
    font-family: 'Barlow';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 76%;
/* or 18px */

text-align: center;

/* White */

color: #FFFFFF;
margin-top: 6.5rem !important;
margin-bottom:2rem;
}

.about-first-img img{
    mix-blend-mode: soft-light;
    display: block;
    max-width: 100%;
    right: -110px;
    top: 21rem;
    position: absolute;
}
.new-class{
position: relative;
z-index: 0; 
}

.slide-para-1{
    position: absolute;
    font-size: 30px;
    color: red;
    z-index: 2;
    top: 30px;
    left: 30rem;
}
@media (max-width:376px){
    .btn-lets2{
        margin-bottom: 1rem;
    }
    .future-p2{
        font-size: 24px;
    }
    .slide-check svg{
        width: 23%;
    }
    .slide-check p{
        font-size: 16px;
    }
    .about-first-sec p{
        color: #FFFFFF;
    }
    .about-first-img img{
        position: absolute;
        right: 45px;
    width: 64%;
    margin-top: 125px;        
    }
}
@media (min-width:376px) and (max-width:1170px){
    .about-first-sec{
        margin-top: -8rem;        
    }    
    .about-first-sec p{
        color: #FFFFFF;
    }
    .top-class{
        padding-top: 0px;
    }
    
    .about-first-img img{
        /* position: absolute;
        right: 45px;
    width: 55%;
    margin-top: 125px;         */
    display: none;
    }
}
@media (min-width:376px) and (max-width:700px){
    .slide-check svg{
        width: 30%;
    }
    
}
@media (max-width:1184px){
    
    .about-first-sec{
        margin-top: -3rem;        
    }
   
}