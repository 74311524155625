.upper-wrapper-for-contactUs{
  background-image: url('../../../public/images/background.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.contact-us1 {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.form-group1 {
  margin-bottom: 15px;
}

.contactUsFormLabels {
  display: block;
  font-weight: bold;
}

.contactUsFormInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}