.Btp-upload{
    font-size: 48px;
    font-weight: 400;
    font-family: 'Barlow';
    line-height: 57.6px;
    color: #191B51;
}
.Btp-body{
    background-color:rgb(222, 215, 222); ;
    overflow-x: hidden;
    position: relative;
}
html {background-color: rgb(222, 215, 222)}
.Btp-header{
    display: flex;
    justify-content: space-between;
    padding: 5rem 7rem;
}
.Btp-cross img{
    cursor: pointer;
}
.Btp-cross{
    
    display: flex;
    align-items: center;
}
@media (max-width:768px){
    .Btp-editor1{
        padding: 0 3rem !important;
        padding-right: 7rem !important;
       
        
    }
    .Btp-button{
        padding: 0 5rem !important;
    }
    .Btp-editorheader{
        padding-left: 5rem !important;
        font-size: 15px;
        font-weight: 600 !important;
        padding-bottom: 1rem;
        padding-top: 2rem;
    
    
    }
    .wrapperClassName{
        border: 1px solid black;
        margin-left: 4rem !important;
        margin-right: 4rem !important;
        border-radius: 10px;
    }
    .Btp-header{
        padding: 5rem 4rem;
    }
    .Btp-row1{
        padding-left: 3rem !important;
        padding-right: 7rem !important;
    }
    .Btp-upload{
        font-size: 6vw;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: 57.6px;
        color: #191B51;
    }
    .Btp-cross img{
        width: 6vw;
    }

    
}


.Btp-row1{
    padding-left: 7rem;
}
.wrapperClassName{
    margin-left: 6rem;
    margin-right: 6rem;
    margin-bottom: 3rem !important;
}
.toolbarClassName{
    border-bottom: 1px solid black !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    
    background-color: #F3F3F3 !important;
    
}
.editorClassName{
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    margin-top: -5px;
    background-color:#FFFFFF !important ;
}
.Btp-editorheader{
    
    padding-left: 7rem;
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 1rem;
    padding-top: 2rem;


}
.Btp-button{
    padding: 0 7rem;
    text-align: end;
    font-size: 18px;
    padding-bottom: 3rem !important;
    }
    
    .Btp-btn1{
        border:  #F3F3F3 !important;
        color: #0A0A13;
        background-color: #FFFFFF;
       padding: 1rem 2rem;
       border-radius: 10px;
       
        }

        .Btp-btn2{
            border:  #F3F3F3 !important;
            color: #FFFFFF;
            background-color: #191B51;
       padding: 1rem 2rem;
       border-radius: 10px;
       margin-left: 2rem;
        }

        @media screen and (max-width:425px){
            .Btp-button{
               font-size: 12px;
                
                }
                .Btp-btn1{
                   
                   padding: 1rem 1.5rem;
                
                   
                    }
                    .Btp-btn2{
                        margin-left: 1.5rem;
                       padding: 1rem 1.5rem;
                      
                       
                        }
                       
        }

.Btp-editor1{
    padding: 0 7rem;
    padding-bottom: 3rem !important;
    position: relative;
    
}
       
       .Btp-toolbar{
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom: 1px solid black;
        background-color: #F3F3F3 !important;
        padding: 0 25px;
        height: 60px;
        display: flex;
        align-items: center;
       
       }
       .Btp-textbar{
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 200px;
        background-color: #FFFFFF;
       }
       /* .Btp-para::after{
        content: '';
        width: 0;
        height: 50px;
        position: relative;
        border: 0.5px solid black;
        top: 0;
        left:0rem ;
       } */
.dropdown-toggle-btn{
    padding: 0 !important;
    /* padding-bottom: 1rem !important; */
    font-size: 16px;
    font-weight: 500;
}
       .dropdown-toggle-btn:hover{
        color: black;

       }

       .Btp-bold{
        /* padding-bottom: 1rem !important; */
         padding-right: 1.5rem;
         padding-left: 3rem;
         font-size: 18px;
         font-weight: 500;
       }
       .Btp-italic{
        /* padding-bottom: 1rem !important; */
        padding: 1rem 1.5rem;
        font-size: 18px;
        font-style: oblique;
        font-weight: 500;
       }
       .Btp-underline{
        /* padding-bottom: 1rem !important; */
        padding: 1rem 1.5rem;
        font-size: 18px;
        text-decoration: underline;
        font-weight: 500;
       }

       .Btp-minimize{
      
        padding-left: 3rem;
       }
       .Btp-minimize img{
        /* padding-bottom: 1rem !important; */
       }
       .Btp-Uppertext{
        /* padding-bottom: 1rem !important; */
           padding: 0rem 1rem;
           font-size: 18px;
           font-weight: 500;
       }
       .Btp-Midtext{
        /* padding-bottom: 1rem !important; */
        padding: 0rem 1rem;
        font-size: 18px;
        font-weight: 500;
       }
       .Btp-Lowertext{
        /* padding-bottom: 1rem !important; */
        padding: 0rem 1rem;
        font-size: 18px;
        font-weight: 500;
       }
       .Btp-align{
           padding: 0 3rem;
       }
       .Btp-align img{
        /* padding-bottom: 1rem !important; */

       }
       .Btp-list{
        padding-right: 3rem;
       }
       .Btp-list img{
        /* padding-bottom: 1rem !important; */

       }
       .Btp-images{
        padding-right: 1rem;
       }
       .Btp-images img{
        /* padding-bottom: 1rem !important; */

       }
       .Btp-link{
        padding-left: 0.5rem
       }
       .Btp-link img{
        /* padding-bottom: 1rem !important; */

       }

       textarea
{
  width:100%;
  padding: 10px 10px;
  border: none;
  resize: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.span-display{
    display: contents;
    
}
textarea:focus-visible{
    outline: none;
}
input:focus-visible{
    outline: none;
}
.Btp-toolbar .Btp-para::after{
    content: '';
    width: 0;
    height: 60px;
    position: absolute;
    border: 1px solid #0A0A13;
    top: 0;
    left: 190px;
    opacity: 0.3;
}
.Btp-toolbar .Btp-para2::after{
    content: '';
    width: 0;
    height: 60px;
    position: absolute;
    border: 1px solid #0A0A13;
    top: 0;
    left: 321px;
    opacity: 0.3;
}
.Btp-toolbar .span-display::after{
    content: '';
    width: 0;
    height: 60px;
    position: absolute;
    border: 1px solid #0A0A13;
    top: 0;
    left: 508px;
    opacity: 0.3;
}
.Btp-toolbar .Btp-align::after{
    content: '';
    width: 0;
    height: 60px;
    position: absolute;
    border: 1px solid #0A0A13;
    top: 0;
    left: 645px;
    opacity: 0.3;
}
.Btp-toolbar .Btp-list::after{
    content: '';
    width: 0;
    height: 60px;
    position: absolute;
    border: 1px solid #0A0A13;
    top: 0;
    left: 734px;
    opacity: 0.3;
}


@media screen and (max-width:944px){
    .Btp-toolbar .Btp-para::after{
       display: none;
    }
    .Btp-toolbar .Btp-para2::after{
        display: none;
    }
    .Btp-toolbar .span-display::after{
        display: none;
    }
    .Btp-toolbar .Btp-align::after{
        display: none;
    }
    .Btp-toolbar .Btp-list::after{
        display: none;
    }
    
    .Btp-toolbar{
        height: 45% !important;
        display: inline-block !important;
        
    }
}
@media screen and (max-width:530px){
    #sidebar:hover h3 {
        position: absolute;
        top: 0;
        transform: none;
        left: 20% !important;
        font-size: 20px;
    }
    .padding-class-top {
        padding: 0px 40px !important;
        margin-top: 8rem !important;
    }
}

#sidebar h3{
    width: 200px;
    position: absolute;
        top: 41%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    left: 50%;
    font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 30px;
/* identical to box height */

letter-spacing: -0.02em;

/* White */

color: #FFFFFF;
}

#sidebar {
    position: fixed;
    z-index: 10;
    /* left: 0; */
    top: 10%;
    right: 0;
    height: 85%;
    background: #191B51;
    border-radius: 100px 0px 0px 100px;
    width: 60px;
    /* background: #fff; */
    border-right: 1px solid #ddd;
    transition: all .3s ease-in-out;
    overflow: hidden;
  }

  #sidebar:hover {
    width: 50%;
  }
  #sidebar:hover .Btp-editor1{
    width: 72% !important;
  }
  #sidebar a {
    text-decoration: none;
    display: block;
    padding: 20px 20px;
    color: #000000;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: bold;
    font-family: Arial;
    width: 30px;
    white-space: nowrap;
    transition: all .2s ease-in-out;
  }
  #sidebar a span {
    opacity: 0;
    transition: all .2s ease-in-out;
  }
  #sidebar:hover a span {
    opacity: 0.2
  }
  #sidebar a:hover span {
    opacity: 1;
  }
  #sidebar a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: black !important;
    width: 150px;
  }
  
  #sidebar a:hover,
  #sidebar a.active {
    text-decoration: none;
    background: #4caf50;
    color: black;
  }
  
  #sidebar a.active {
    background: #00ff00;
  }

  .padding-class-top{
    padding: 0px 80px;
    margin-top: 7rem;
  }
  #sidebar:hover h3{
    position: absolute;
    top: 0;
    transform: none;
    left: 9%;
  }
  .image-blog{
    margin-bottom: 1.5rem;
  }
  .image-blog img{
    width: 100%;
  }

  @media (max-width:320px){
    #sidebar:hover h3 {
        position: absolute;
        top: 10px;
        transform: none;
        left: 9%;
    }
  }
  @media (min-width:321px) and (max-width:375px){
    #sidebar:hover h3 {
        position: absolute;
        top: 10px;
        transform: none;
        left: 9%;
    }
  }
  @media (min-width:376px) and (max-width:435px){
    #sidebar:hover h3 {
        position: absolute;
        top: 10px;
        transform: none;
        left: 9%;
    }
    .image-blog img {
        padding-left: 14px;
        width: 95%;
    }
  }

  @media (min-width:436px) and (max-width:530px){
    #sidebar:hover h3 {
        position: absolute;
        top: 10px;
        transform: none;
        left: 9%;
    }
    .image-blog img {
        padding-left: 39px;
        width: 85%;
    }
  }

  @media (min-width:531px) and (max-width:577px){
    #sidebar:hover h3 {
        position: absolute;
        top: 10px;
        transform: none;
        left: 15%;
        font-size: 23px;
    }
    
  }

  @media (min-width:578px) and (max-width:650px){
    #sidebar:hover h3 {
        position: absolute;
        top: 10px;
        transform: none;
        left: 15%;
        
    }
    .image-blog img {
        padding-left: 26px;
        width: 89%;
    }
  }
  @media (min-width:651px) and (max-width:710px){
    #sidebar:hover h3 {
        position: absolute;
        top: 10px;
        transform: none;
        left: 15%;
        
    }
    .image-blog img {
       
    padding-left: 36px;
    width: 85%;

    }
  }
  @media (min-width:711px) and (max-width:767px){
    #sidebar:hover h3 {
        position: absolute;
        top: 10px;
        transform: none;
        left: 15%;
        
    }
    .image-blog img {
        padding-left: 56px;
        width: 83%;
    }
  }

  @media (width:320px){
    textarea {
        width: 104%;
       
    }
  }
  .btn-para:hover{
    border: none !important;
    color: black !important;
  }