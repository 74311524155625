* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

.outer-changes {
    position: relative;
    /* width: 100%; */
    overflow-x: hidden;
}

.header-wrap {
    /* background-image:     linear-gradient(to bottom, rgba(8, 81, 97, 0),  rgb(1, 16, 52)), 
    url('/public/images/background.png'); */
    background-image: url('/public/images/Homepage_background.jpg');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.header-pad {
    padding: 65px 85px !important;
}

.color-check {
    background: #32179C;
    /* mix-blend-mode: color; */
    mix-blend-mode: hard-light;
}

.margin-check {
    margin: 20px 0px;

}

.future-p {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 800;
    font-size: 57.981px;
    line-height: 44px;
    color: #FFFFFF;
}

.water-p {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 800;
    font-size: 85px;
    line-height: 65px;

    color: #FFFFFF;
}

.our-p {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 57.981px;
    line-height: 70px;
    /* identical to box height */
    color: #FFFFFF;
}

.top-class {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.btn-lets {
    background: #FFFFFF;
    border-radius: 12.5641px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 20.1026px;
    line-height: 24px;

    /* Black */

    color: #0A0A13;
    padding: 14px 12px;
    border: none;
}

.distance-top {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 76%;
    /* or 34px */

    text-align: center;

    /* White */
    margin-bottom: 10px;

    color: #FFFFFF;
}

.decs-bot {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
}

.sec-left {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-family: 'Italiana';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 76%;
    text-align: center;
    color: #0A0A13;
}

.sec-left2 {
    font-family: 'Italiana';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 180%;
    /* or 68px */
    position: relative;
    z-index: 1;


    /* Black */

    color: #0A0A13;

    /* Black */

}

.align-items {
    display: flex;
    align-items: center;
    padding: 0 30px;
    overflow-y: scroll;
    max-height: 490px;
    overflow-x: hidden;
    margin-right: 30px;
}

.image-muthai img {
    width: 100%;
}

/* .align-items::-webkit-scrollbar-track{
	border-radius: 20%;
  border: 1px solid blue;
  width: 50px;
}
.align-items::-webkit-scrollbar{
    width: 15px;
	background-color: blue;
  border-radius: 20%;
}
.align-items::-webkit-scrollbar-thumb{
    border-radius: 20%;
	background-color: red;
  border: 5px solid blue;
  background-image: url("/public//images/Polygon-2.png");
} */

.align-items::-webkit-scrollbar {
    width: 10px;
}

.align-items::-webkit-scrollbar-track {
    background: #AF7B3F;
    border: 4px solid transparent;
    background-clip: content-box;
}

.align-items::-webkit-scrollbar-thumb {
    border-radius: 95px;
    background: #77450C;
}

.p-tag-sec {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 180%;
    /* or 40px */
    position: relative;
    z-index: 1;

    /* Black */

    color: #0A0A13;
}

.header-wrap2 {
    background-image: url('/public/images/Background (1).png');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.sec-left3 {
    font-family: 'Italiana';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 76%;
    /* or 49px */

    text-align: center;

    /* White */
    margin-top: 4rem;
    margin-bottom: 2rem;
    color: #FFFFFF;
}

.detail-h6 {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 180%;
    /* identical to box height, or 54px */


    /* White */

    color: #FFFFFF;
}

.detail-p {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */


    /* White */

    color: #FFFFFF;
}

.align-items2 {
    padding: 0 30px;

}

.new-align {
    display: flex;
    justify-content: center;
}

.resolution-sec {
    background: #24289E;
    border-radius: 20px;
    height: 270px;
    width: 90%;
    display: flex;
    margin: 20px 72px;
}

.resolution-sec h6 {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 180%;
    /* identical to box height, or 54px */


    /* White */

    color: #FFFFFF;
}

/* .resolution-sec p {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 180%;
    color: #FFFFFF;
} */

.chacha-image {
    position: relative;
}

.chacha-image img {

    width: 78%;
    position: absolute;
    top: -32px;
    left: 0px;

}

.pad-check {
    padding: 15px 7px;
}

.pad-check span {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 180%;
    /* or 40px */


    /* White */

    color: #FFFFFF;
}

.mision-sec {
    background: #F4F4F4;
    padding-bottom: 2rem;
}

.sec-left4 {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-family: 'Italiana';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 76%;
    /* or 49px */

    text-align: center;

    /* Black */

    color: #0A0A13;
}

.sec-left-5 {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 180%;
    /* or 40px */

    text-align: center;

    /* Black */

    color: #0A0A13;
}

.cards-vision {
    background: #FFFFFF;
    border-radius: 10px;
    height: 370px;
}

.top-part h5 {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 180%;
    /* identical to box height, or 54px */

    text-align: center;
    text-transform: uppercase;

    /* Med Blue */

    color: #1C21AB;
}

.top-part {
    text-align: center;
}

.top-part1 span {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
    color: #0A0A13;
}

.top-part1 {
    margin: 0px 25px;
}

.top-part1 p {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 180%;
    /* or 32px */
    padding: 0px 14px;

    /* Black */

    color: #0A0A13;
}

.top-part img {
    margin-top: 2.5rem;
}

.bottom-check {
    padding-bottom: 3rem;
}

.hp-pink-img {
    position: absolute;
    margin-top: -35px;
}

.muthai-para {
    position: relative;

}

.pink-img {
    position: absolute;
    top: -3rem;
    left: -3rem;
    z-index: 0;
}

.pink-img2 {
    position: absolute;
    top: 5rem;
    left: -3rem;
    z-index: 0;
}

.pink-img3 {
    position: absolute;
    top: 20rem;
    left: -3rem;
    z-index: 0;
}

@media screen and (max-width:575px) {
    .sec-left {
        font-size: 44px !important;
    }

    .header-pad {
        padding: 30px 48px !important;
    }

    .top-part1 p {
        padding: 0px 30px;
    }

    .cards-vision {
        height: 495px;
        margin-bottom: 1rem;
    }

    header {
        padding: 30px 48px !important;
    }

    .p-tag-sec {
        font-size: 15px;
    }

    .sec-left3 {
        font-size: 44px !important;
    }

    .detail-h6 {
        font-size: 25px;
    }

    .detail-p {
        font-size: 13px;
    }

    .resolution-sec p {
        font-size: 8px;
    }

    .sec-left-5 {
        font-size: 11px;
    }

    .sec-left4 {
        font-size: 44px;
    }

    .header-wrap2 {
        height: 100% !important;
    }

    .future-p {
        font-size: 32px;
    }

    .water-p {
        font-size: 40px;
    }

    .our-p {
        font-size: 32px;
    }

    .pad-check span {
        font-size: 13px;
    }

    .slider-images {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 10px;
    }

    .chacha-texts {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 180%;
        color: #FFFFFF;
        text-align: left;
        overflow: auto;
        display: flex;
        justify-content: flex-start;
    }

    .chacha-names {
        font-style: normal;
        font-weight: 300;
        font-size: 8px;
        color: #FFFFFF;
        margin-right: 25%;
        margin-top: 45px;
    }

}

@media screen and (max-width:1200px) {
    .resolution-sec {
        height: 100%;
        width: 100%;
        margin-left: 0px;
    }

    .cards-vision {
        height: 340px;
        margin-bottom: 1rem;
    }

    .resolution-sec h6 {
        font-size: 25px;
    }

    .chacha-image img {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
    }

    .top-part h5 {
        font-size: 18px !important;
    }

    .top-part1 span {
        font-size: 11px !important;
    }

    .slider-images {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 10px;
    }

    .chacha-texts {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 180%;
        color: #FFFFFF;
        text-align: left;
        overflow: auto;
        display: flex;
        justify-content: flex-start;
    }

    .chacha-names {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        color: #FFFFFF;
        margin-right: 25%;
        margin-top: 45px;
    }

    .resolution-sec p {
        font-size: 12px;
    }

}

@media screen and (min-width:1200px) {
    .resolution-sec {
        height: 100%;
        width: 100%;
        margin-left: 0px;
    }

    .cards-vision {
        height: 340px;
        margin-bottom: 1rem;
    }

    .resolution-sec h6 {
        font-size: 25px;
    }

    .chacha-image img {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
    }

    .top-part h5 {
        font-size: 18px !important;
    }

    .top-part1 span {
        font-size: 11px !important;
    }

    .slider-images {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 10px;
    }

    .chacha-texts {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 180%;
        color: #FFFFFF;
        text-align: left;
        overflow: auto;
        display: flex;
        justify-content: flex-start;
    }

    .chacha-names {
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        color: #FFFFFF;
        margin-right: 25%;
        margin-top: 45px;
    }

    .resolution-sec p {
        font-size: 15px;
    }

}

::-webkit-scrollbar {
    width: 0;
}

.sec-new-change {
    text-align: center;
    margin-bottom: 3rem;
}


@media (min-width: 220px) and (max-width:425px) {
    .top-part h5 {
        font-size: 10px !important;
    }

    .chacha-texts {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 180%;
        color: #FFFFFF;
        text-align: left;
        overflow: auto;
        display: flex;
        justify-content: flex-start;
    }

    .chacha-names {
        font-style: normal;
        font-weight: 300;
        font-size: 8px;
        color: #FFFFFF;
        margin-right: 25%;
        margin-top: 45px;
    }

    .slider-images {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 10px;
    }

    .align-items2 {
        padding: 0px;
    }

    .resolution-sec p {
        font-size: 7px;
    }
}


.slider-container {
    max-width: 1300px;
    margin: 0 auto;
}

.slider-item {
    padding: 20px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #24289E;
    border-radius: 20px;
    height: 400px;
}

/* .chacha-texts {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 180%;
    color: #FFFFFF;
    text-align: left;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
}
.chacha-names {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #FFFFFF;
    margin-right: 25%;
    margin-top: 45px;
} */

.chacha-right {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 180%;
    color: #FFFFFF;
    margin-left: 92%;
    margin-top: 14%;
}

/* .slider-images {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
} */