.pdf-block {
    width: 200px;
    height: 250px;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    display: inline-block;
    text-align: center;
  }

  .pdf-block embed {
    width: 100%;
    height: 100%;
  }

  .openpdf{
    text-align: center;
    margin-bottom: 3rem;

  }
  .openpdf img{
    width: 100%;
  }
  .openpdf a{
    background: #191B51;
    border-radius: 5px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    display: block;
    padding: 12px 0px;
    width: 100%;
    margin: auto;
  }
  .nonebackground{
    background: transparent !important;
    height: 200px !important;
  }

  .sec-left-open{
    margin-top: 0rem;
    margin-bottom: 6rem;
    font-family: 'Italiana';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 76%;
    text-align: center;
    color: #0A0A13;
  }

  .nodata{
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .pdfpreview{
    height: 300px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  /* .iframe{
    height: -webkit-fill-available;
    width: -webkit-fill-available;
  } */
  #main{
    height: 174px !important;
    overflow-y: hidden !important;
  }
  /* .openpdf {
    overflow-y: hidden !important;
  }
  .iframe {
    overflow-y: hidden !important;
  } */
  iframe.iframe {
    overflow-y: hidden !important;
  }
  iframe.iframe {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    overflow: -moz-scrollbars-none; /* Old versions of Firefox */
    overflow: hidden; /* Other browsers */
  }
  section {
    overflow-y: hidden;
    /* margin-bottom: -17px !important; */
  }