header{
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    padding: 30px 85px;
}

.logo img{
    width: 70%;
}

nav{
    display: flex;
    align-items: baseline;
    justify-content: end;
}

nav li{
    display: inline-block;
    list-style: none;
    /* padding: 10px 20px; */
}
.d-pad li{
    padding: 0px 9px !important;
}
.hed-font::after {
    content: none !important;
}
.notification-icon::after{
    content: none !important;
}
.notification-icon svg{
    margin-left: 7px;
}
.hed-font svg{
    margin-left: 12px;
}
.hed-font{
    font-family: 'Barlow';
font-style: normal;
font-weight: 600 !important;
font-size: 16px !important;
line-height: 19px;
display: flex;
align-items: center;
text-align: center;

/* Black */

color: #0A0A13;
}
nav li a{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    
    /* Black */
    
    color: #0A0A13;
}
a,button{
    text-decoration: none !important;
    cursor: pointer;
}

.btn-don {
background: #191B51;
border-radius: 10px;
font-family: 'Barlow';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

/* White */
padding: 10px 10px;
color: #FFFFFF;
margin-right: 1rem;
border: none;
}
.btn-login {
    background: #FFFFFF;
border-radius: 10px;
padding: 10px 18px !important;
font-family: 'Barlow';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
z-index: 1;
/* Black */

color: #0A0A13;
border: none;
}

nav .navbar-toggle{
position: absolute;
top: 75px;
right: 50px;
width: 35px;
display: none;
}
.navlinks{
    display: flex;
    align-items: inherit;
}
.checkbtn{
    font-size: 30px;
    color: black;

}
.check{
    display: none;
}
/* @media screen and (max-width:1278px){
    nav{
        padding: 0;
    }
    .navlinks{
        position: absolute;
        backdrop-filter: blur(20px);
        width: 100%;
        justify-content: center;
        align-items: center !important;
        top: 140px;
        left: -100%;
        transition: all 0.5s;
    }
    .navlinks ul{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .navlinks{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    nav .navbar-toggle{
        display: block;
    }
    nav li{
        
        padding: 10px 20px;
    
    }
    .check:checked .navlinks {
        left: 0% !important;
    }
   
} */
.navbar-toggle{
    display: block;
}
/* @media (min-width:1279px){
    .navbar-toggle{
        display:none;
    }
} */
/* @media (max-width:1279px){
    .ul-hide{
        display:none !important;
    }
} */
.btn-color{
    background: #191B51;
    /* float: right; */
    color: #FFFFFF;
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 6px
 18px;
}
.btn-text-col{
    color: #FFFFFF;
    padding: 7px 17px;
}
.btn-text-col:hover{
    color: #FFFFFF;
}
.btn-color svg{
    margin-right: 8px;
}
.active-class ul li .active{
    color: #1C21AB !important;
    display: block;
    content: "";
    /* border: 1px solid #1C21AB; */
    border-bottom: 2px solid #1C21AB;
    line-height: 30px;    
    /* transform: translateY(-0.4rem); */
}
.active-class ul li .active{
    color: #1C21AB !important;    
}

/* .active-class ul li a::after{
    display: block;
    content: "";
    height: 3px;
    background-color: coral;
    margin: auto;
      
    
}
.active-class ul li a:hover::after{
    width: 100%;
    transition: width 0.3s linear;
} */

.nav-link{
    color: #0A0A13;
}
.dropdown1{
    position: relative;
    float: right;
    /* margin-bottom: 2rem; */
    
}
.dropdown{
    float: none !important;
    font-family: 'Barlow';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
}
.dropdown-toggle::after{
    display: inline-block;
    margin-left: 1rem !important;
    vertical-align: 0.2rem !important;
    content: "";
    font-size: 20px !important;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.btn-language{
    background: #191B51;
    border-radius: 5px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}
.btn-language:hover{
    /* background: #191B51;
    border-radius: 5px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center; */
    color: #FFFFFF;
}
.svg-btn{
    margin-right: 10px;
}
/* .btn.focus, .btn:focus, .btn:hover{
    color: #ffffff;
} */
.btn-check:focus+.btn, .btn:focus{
    box-shadow: none;
}
ul li a:hover{
    text-decoration: none;
    color: black;
}
.img-pro{
    float: right;
}
.img-profile-padding{
    padding: 0px !important;
}
.header-drop-menu .dropdown-item{
    font-size: 20px;
}
.dropdown-menu{
    padding: 0.5rem 10px !important;
}
.pro-icon{
    float: right;
    margin-top: 1rem;
}
.pro-icon-font li{
    font-size: 13px !important;
}
.bell-side-pad{
    padding: 0px !important;
}
.hedmarker{
    list-style: none;
}
/* @media (min-width:992px){
    .loginbtn-header{
        display: none;
    }
    
} */
.btn-success{
    background: none;
    border: none;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0A0A13;
}
.rd-btn-main:hover{
    background: none !important;
}
.btn-success:hover{
    background: none;
    color: #0A0A13 !important;
}
.btn-check:active+.btn-success, .btn-check:checked+.btn-success, .btn-success.active, .btn-success:active, .show>.btn-success.dropdown-toggle{
    background: none;
    color: #0A0A13;
    border: none;
}
.rd-btn-main::after{
    content: none;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle{
    background: none !important;
    border: none !important;
}
.btn-success.focus, .btn-success:focus {
    color: #0A0A13 !important;
    background-color: none !important;
    border-color: none !important;
    box-shadow: none !important;
}
.btn-success{
    display: block !important;
}