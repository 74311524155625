.btn-group-div{
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: center;
    padding: 10px 60px;
}

.view-more-btn{
    padding: 10px 30px;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
}