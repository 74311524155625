@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.container-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gif-error {
  display: flex;
  justify-content: center;
}

.content-error {
  text-align: center;
  margin: 3rem 0;
}

.content-error .main-heading-error {
  font-size: 2.5rem;
  font-weight: 700;
}
.p-error {
  font-size: 1.3rem;
  padding: 0.7rem 0;
}

.button-error {
  padding: 1rem;
  border-radius: 15px;
  outline: none;
  border: none;
  background: #0046d4;
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
}
