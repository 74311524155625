/* a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
} */

/* a,
a:active,
a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
} */
.input-group .form-control:focus{
    z-index: 0 !important;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.footer-img {
    width: 60%;
}
/* section {
    padding: 60px 0;
    min-height: 100vh;
} */
.footer {
    padding: 37px 0px;
background: #191B51;
}


/* .footer .widget1 p {
font-size: 16px;
color: rgba(255, 255, 255, .6);
margin-top: 26px;
}

.footer .socialLinks {
margin-top: 28px;
}

.socialLinks ul li {
display: inline-block;
}


.socialLinks ul li a i {
display: block;
margin: 0 2px;
width: 40px;
height: 40px;
background: #fafafa;
border-radius: 50%;
text-align: center;
margin-right: 5px;
line-height: 40px;
-webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}

.socialLinks ul li a .fa-facebook-f {
color: #4267b2;
}

.socialLinks ul li a .fa-twitter {
color: #1da1f2;
}

.socialLinks ul li a .fa-google-plus-g {
color: #dd5044;
}

.socialLinks ul li a .fa-linkedin-in {
color: #0177b5;
}


.socialLinks ul li a:hover i {
color: #fff;
}

.socialLinks ul li a:hover .fa-facebook-f {
background: #4267b2;
}

.socialLinks ul li a:hover .fa-twitter {
background: #1da1f2;
}

.socialLinks ul li a:hover .fa-google-plus-g {
background: #dd5044;
}

.socialLinks ul li a:hover .fa-linkedin-in {
background: #0177b5;
}
footer .logo {
max-width: 200px;
} */

.footer h5 {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 180%;  
    
    color: #FFFFFF;
}
.footer h4{
    font-family: 'Barlow';
font-style: normal;
font-weight: 400;
font-size: 21px;
line-height: 180%;
color: #FFFFFF;
margin-bottom: 0px !important;
}
.footer h3{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 180%;    
    color: #FFFFFF;
}
/* .footer .widget2 .media img {
margin-right: 20px;
max-width: 100px;
} */
/* .footer .widget2 .media p {
font-size: 16px;
color: rgba(255, 255, 255, .8);
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
font-weight: 600;
line-height: 26px;
} */

/* .footer .widget2 .media span {
font-size: 12px;
color: #ffb606;
text-transform: uppercase;
margin-top: 15px;
display: block;
} */

/* .footer .widget2 .media {
margin-bottom: 20px;
} */

.f-no{
    font-family: 'Barlow';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 180%;
/* identical to box height, or 54px */


color: #FFFFFF;
}
.footer .widget4 ul li a {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 250%;
    /* or 40px */
    
    
    color: #FFFFFF;
}
.f-input{
    background: #FFFFFF;
border-radius: 10px;
font-family: 'Barlow';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;
padding: 10px 15px;
/* Black */

color: #0A0A13;
}
.f-inp-text  .input-group{
    /* width: 86% !important; */
}
.input-group1{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.f-btn{
    background: #1C21AB;
border-radius: 10px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 15px 15px !important;
gap: 10px;
font-family: 'Barlow';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #FFFFFF;
flex: none;
order: 0;
flex-grow: 0;
/* position: absolute !important;
    border-radius: 8px;
    left: 14rem; */
}
.f-btn:hover{
    border: none !important;
}
.f-inp-text .input-group{
    width: 82% !important;
}
.f-inp-grup button:hover{
    background: #1C21AB !important;
}
.footr-inp{
    font-family: 'Barlow';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;

/* Black */

color: #0A0A13;
}
.f-flex{
    /* display: flex; */
    padding: 10px 50px;
    justify-content: center;
    align-items: center;
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    margin-left: -10px !important;
    border-top-left-radius: 1 !important;
    border-bottom-left-radius: 1 !important;
}
/* .footer .widget3 ul li a:hover,
.footer .widget4 ul li a:hover,
.footer .widget2 a:hover p {
color: #ffb606;
} */

.footer .widget3 h5 {
    /* margin-top: 25px

!important; */
/* margin-bottom: 22px; */
font-weight: 600;
color: #fff;
font-size: 21px;
line-height: 32px;
}

.footer .widget4 h5 {
/* margin-bottom: 22px; */
font-weight: 600;
color: #fff;
font-size: 21px;
}
.f-toll-no{
    margin-top: 29px;
}
.f-toll-no p{
    font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 180%;
/* identical to box height, or 54px */


color: #FFFFFF;
}
.f-cont-no{
    margin-top: 60px;
}
.f-cont-no p{
    font-family: 'Barlow';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 180%;
color: #FFFFFF;
}
.input-group-append{
    position: absolute;
    right: -32px;
    top: 5px;
}
@media (max-width:425px){
    
    /* .footer{
        padding: 0px 25px;
    } */
    .f-flex{
        padding: 10px 20px;
    }
    .f-btn{
        padding: 15px 5px !important;
        font-size: 14px;
    }
}
@media (max-width:768px){    
    .footer{
        padding: 0px 25px;
    }
    .last-bloc{
        padding-top: 15px;
    }
}
@media (max-width:992px){
    .footer-img{
        width: 50%;        
    }
    
}
