.as-hed{
    display: flex;
    padding: 30px 70px;
    justify-content: space-between;
    align-items: center;
}
.as-hed p{
    font-family: 'Barlow';
font-style: normal;
font-weight: 400;
font-size: 48px;
line-height: 58px;
letter-spacing: -0.02em;
color: #191B51;
}
.as-img{
text-align: center;
position: relative;
}
.as-img img{
    width: 15%;
    position: relative;
}
.as-img svg{
    position: absolute;
    left: 53%;
    /* top: 44%; */
    bottom: 0;
}
.as-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 7px;
}
.as-form label{
    font-family: 'Barlow';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
/* identical to box height */

text-align: center;
letter-spacing: -0.02em;

/* Black */

color: #0A0A13;
}
.as-imp{
    font-family: 'Work Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */

letter-spacing: -0.02em;

/* Black */

color: #0A0A13;
}
.as-form input{
    background: #F0F3F3 !important;
    border-radius: 10px;
}
.as-form svg{
    position: absolute;
    top: 50%;
    right: 7%;
}
.as-btn{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 15px;
gap: 10px;
background: #191B51;
border-radius: 10px;
font-family: 'Barlow';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

/* White */
margin-left: 30rem;
color: #FFFFFF;
}

@media (min-width:375px) and (max-width:425px){
    .as-btn{
margin-left: 25rem;
    }
}
@media(min-width:320px) and (max-width:375px){
    .as-btn{
margin-left: 19rem;
    }
}
@media  (max-width:320px){
    .as-btn{
margin-left: 14rem;
    }
}
@media (min-width:425px) and (max-width:576px){
    .as-btn{
margin-left: 14rem;
    }
}
@media (min-width:576px) and (max-width:768px){
    .as-btn{
margin-left: 2rem;
    }
}
@media  (min-width:768px) and (max-width:1024px){
    .as-btn{
margin-left: 7rem;
    }
}
@media  (min-width:280px) and (max-width:375px){
    .as-hed p{
        font-size: 30px;
    }
}
@media  (min-width:375px) and (max-width:500px){
    .as-hed p{

    }
}

@media screen and (max-width:425px){
    .as-img svg{
        width: 20px;
    }
}